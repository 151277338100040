import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import SEO from '../components/seo';
import '../styles/theme.scss';

const Blogs = () => (
  <>
    <SEO title="Blogs | Jimmy Truong" />
    <Container fluid>
      <Row className="pageHeader">
        <Col>
          <h1>BLOGS</h1>
        </Col>
        <Col>
          <h5>
            Selection of design and development resources, things I found useful
            along the way.
          </h5>
        </Col>
      </Row>
      <Row className="ProjectsContainer" />
    </Container>
  </>
);
export default Blogs;
